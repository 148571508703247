/* eslint-disable import/prefer-default-export */
export const fieldList = [
  {
    key: 'name',
    label: 'Mitra Gudang',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'active_date',
    label: 'Tanggal Mulai Aktif',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'owner',
    label: 'Owner',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'availability',
    label: 'Ketersediaan',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'rincian',
    label: 'Rincian',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
]

export const fieldDetail = [
  {
    key: 'submission_date',
    label: 'Tanggal Berlangganan',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'bg-white p-1',
  },
  {
    key: 'name',
    label: 'Partner',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'bg-white p-1',
  },
  {
    key: 'owner',
    label: 'Owner',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'bg-white p-1',
  },
  {
    key: 'product_total',
    label: 'Produk',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'bg-white p-1 text-center',
  },
  {
    key: 'submission_status',
    label: 'Status',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'bg-white p-1 text-center',
  },
  {
    key: 'detail',
    label: 'Rincian',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'bg-white p-1 text-center',
  },
]

export const fieldDetailProduct = [
  {
    key: 'product_name',
    label: 'Nama Produk',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '200px',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
  },
  {
    key: 'variant',
    label: 'Variasi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '100px',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
  },
  {
    key: 'stock',
    label: 'Stok Produk',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '150px',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
  },
  {
    key: 'sold',
    label: 'Total Terjual',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '150px',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
  },
  {
    key: 'fulfillment_cost',
    label: 'Biaya Fulfillment',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '200px',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
  },
  {
    key: 'income',
    label: 'Biaya Tambahan',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '300px',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
  },
]

export const fieldsBundling = [
  {
    key: 'no',
    label: 'No',
    thClass: 'font-[500] text-[#222] !text-[14px] !capitalize bg-white',
    tdClass: 'text-black',
  },
  {
    key: 'product_name',
    label: 'Nama Produk',
    thClass: 'font-[500] text-[#222] !text-[14px] !capitalize bg-white',
    tdClass: 'text-black',
  },
  {
    key: 'qty',
    label: 'Jumlah',
    thClass:
'font-[500] text-[#222] !text-[14px] !capitalize text-center bg-white',
    tdClass: 'text-black text-center',
  },
]
